export const Instructions = () => {
  return (
    <div className="instructions">
        <ol>
            <li>Connect your wallet.</li>
            <li>Input the number of NFTs you wish to mint per txn.</li>
            <li>Click "MINT" and confirm the transaction that pops up in your wallet.</li>
        </ol>
        <br />
        <i>Note: If you are on whitelist you will also need to enter your coupon code to mint.</i>
    </div>
  );
};
