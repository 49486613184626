import React, {useState, useEffect} from "react";
import { ethers, BigNumber} from "ethers";
import { company_name, logo, help_discord_link} from "../config";
import loadingAsset from "../assets/loading.gif";
import ethSymbol from "../assets/eth-symbol.svg";
import { useNavigate } from "react-router-dom";
import { Instructions } from '../components/instructions.js';

// connection = [provider, signer, signer_address, nft_contract]
const WhitelistMint = ({connection, setConnection}) => {
  let navigate = useNavigate();

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [tokensToMint, setTokensToMint] = useState("");
  const [coupon, setCoupon] = useState("");
  const [readyFlag, setReadyFlag] = useState(false);

  const [totalAmountAvailableToMintPerPerson, setTotalAmountAvailableToMintPerPerson] = useState(0);
  const [totalAmountMinted, setTotalAmountMinted] = useState(0);
  const [totalAmountLeft, setTotalAmountLeft] = useState(0);
  const [price, setPrice] = useState(0);
  const [statusMsg, setStatusMsg] = useState("");


  async function initializeValues(){
    const total_supply = BigNumber.from(await connection.nft_contract.totalTokenSupply()).toNumber();
    const tokens_minted = BigNumber.from(await connection.nft_contract.tokensMinted()).toNumber();
    const tokens_minted_by_client = BigNumber.from(await connection.nft_contract.personMintCount(connection.signer_address)).toNumber();
    const price = ethers.utils.formatEther(ethers.utils.parseUnits((await connection.nft_contract.WL_price()).toString(), "wei"));
    const preSale = await connection.nft_contract.preSale();
    const saleOpen = await connection.nft_contract.saleOpen();
    const tokens_per_person = BigNumber.from(await connection.nft_contract.MAX_PRESALE_MINTS_PER_ADDRESS()).toNumber();
 
    setTotalAmountAvailableToMintPerPerson(tokens_per_person);
    // setTotalAmountMinted(10);
    setTotalAmountMinted(tokens_minted_by_client);
    setTotalAmountLeft(tokens_per_person - tokens_minted_by_client);
    setPrice(price.toString());

    setReadyFlag(true);
    
    if(preSale && saleOpen){
      setStatusMsg("Completed");
    }
    else if(preSale && total_supply > tokens_minted){
      setStatusMsg("In Progress");
    }   
    else{
      setStatusMsg("Closed");
    }
  }

  useEffect(() => {
    if(connection) initializeValues();
  }, [connection]);

  async function mintNFT(){
    if(readyFlag){
      setError();
      setSuccess();
      if(statusMsg != "In Progress"){
        setError("Presale is closed.")
      }
      else if(!tokensToMint){
        setError("Please enter amount to Mint.")
      }
      else if(tokensToMint > totalAmountLeft){
        setError("Amount exceeds the amount of tokens left to Mint.")
      }
      else if(tokensToMint <= 0){
        setError("Min 1 token can be minted.")
      }
      else if(coupon === ""){
        setError("Please enter coupon.")
      }
      else{
        try{
          setReadyFlag(false);

          const couponArr = coupon.split(";");

          const couponObj = {r: couponArr[0], s: couponArr[1], v: couponArr[2]};

          let wei_price = ethers.utils.parseEther(price).mul(tokensToMint);

          let transaction = await connection.nft_contract.mintPresale(tokensToMint, couponObj, {value: wei_price,});
          await transaction.wait();

          setSuccess("Transaction completed!");

        }catch(e){
          if(e.reason){
            setError("Minting Error: " + e.reason);
          }
          else{
            setError(e.message);            
          }
        }
        finally{
          initializeValues();
        }
      }
    }
    else{
      setError("Please wait.");
    }
  }

  return (
    <div className="content">
      <div className="company">
        <h1>{company_name}</h1>
      </div>

      <div className="logo">
        <img src={logo} alt={company_name} width="300" height="150"></img>
      </div>

      <hr></hr>

      <div className="discord">
        <a href={help_discord_link}>HELP / DISCORD</a>
      </div>

      <div className="loading">
        {!readyFlag && 
          <img src={loadingAsset} width="75" height="75"/>
        }
      </div>

      <div className="error">
        {error}
      </div>

      <div className="success">
        {success}
      </div>

      <Instructions />


    <div className="wallet">
      <div className="nftInformation">
      <div className="flexcolumn">
        <div>
          For You
        </div>
        <div className="infoText">
          {totalAmountLeft}/{totalAmountAvailableToMintPerPerson}
        </div>
      </div>
      <div className="flexcolumn">
        <div>
          Price
        </div>
        <div className="infoText">
          <img src={ethSymbol} id="eth" width="20" height="20" alt="ETH"/> 
          {price}
        </div>
      </div>
      <div className="statusBox">
        <div className="status">
          {statusMsg}
        </div>
      </div>
      </div>
      <input className="input" placeholder="Enter Coupon" type="text" value={coupon} onChange={(event) => {setError(); setSuccess(); setCoupon(event.target.value);}}></input>
      <input className="input" placeholder="Enter Amount to Mint" type="number" value={tokensToMint} onChange={(event) => {setError(); setSuccess(); setTokensToMint(event.target.value);}}></input>
      <button className="button"  onClick={mintNFT}>MINT</button>
    </div>
    <button className="navigateButton" onClick={() => navigate("/Mint")}>Go to Sale Page</button>
  </div>
  );
};

export default WhitelistMint;