import React from "react";
import { company_name, logo, help_discord_link} from "../config";
import { Instructions } from '../components/instructions.js';

const Home = ({setConnectFlag, error}) => {

  function connect_account(){
    setConnectFlag([true, false]);
  }

  return (
    <div className="content">
      <div className="company">
        <h1>{company_name}</h1>
      </div>

      <div className="logo">
        <img src={logo} alt={company_name} />
      </div>

      <hr></hr>

      <div className="discord">
        <a href={help_discord_link}>HELP / DISCORD</a>
      </div>

      <div className="error">
        {error}
      </div>

      <Instructions />


      <div className="wallet">
        <button onClick={connect_account} className="button" >CONNECT WALLET</button>
    </div>  
  </div>
  );
};

export default Home;