import React, {useState, useEffect} from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";

import {RequireAcc, HaveAcc} from "./components";
import {Home, Mint, WhitelistMint} from "./views";

import Web3Modal from "web3modal";
import { ethers } from "ethers";

import {contract_address, NFT_ABI, network_name} from "./config";


function App(){
  const [connection, setConnection] = useState();  //[provider, signer, signer_address, nft_contract]
  const [connectFlag, setConnectFlag] = useState([false, false]);  //[0] used to call connect_account(); [1] used to route;
  const [error, setError] = useState();
  const ethereum = window.ethereum;

  async function connect_account(){
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const network = await provider.getNetwork();
    if(network.name === network_name){
      const signer = provider.getSigner();
      let signer_address = await signer.getAddress();
      const nft_contract = new ethers.Contract(contract_address, NFT_ABI, signer);

      setConnection({provider: provider, signer: signer, signer_address: signer_address, nft_contract: nft_contract});
      
      setConnectFlag([false, true]);
    }
    else{
      setError("Please switch to " + network_name + " network");
    }
  } 
  
  if(ethereum){
    ethereum.on('accountsChanged', (accounts) => {
      if(accounts.length !== 0){
        setConnection();
        setConnectFlag([true, false]);
      }
      else{
        setConnection();
        setConnectFlag([false, false]);
      }
    })

    ethereum.on('chainChanged', (chainId) => {
      window.location.reload();
    });
  }

  useEffect(() => {
    if(connectFlag[0]){
      setError();
      connect_account();
    }
  }, [connectFlag]);
  
  
  return (
    <Routes>
      <Route
        index 
        element={
          <HaveAcc connect_flag={connectFlag}>
            <Home setConnectFlag={setConnectFlag} error={error}/>
          </HaveAcc>
          }
      ></Route>
      <Route
        path="/Mint"
        element={
          <RequireAcc connect_flag={connectFlag}>
            <Mint  connection={connection} setConnection={setConnection}/>
          </RequireAcc>
        }
      ></Route>
      <Route
        path="/WhitelistMint"
        element={
          <RequireAcc connect_flag={connectFlag}>
            <WhitelistMint  connection={connection} setConnection={setConnection}/>
          </RequireAcc>
        }
      ></Route>
    </Routes>
  );
}

export default App;