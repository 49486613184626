import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

export const RequireAcc = ({ connect_flag, children }) => {
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (!connect_flag[1]) {
      navigate("/", { state: { from: location } });
    }
  }, [connect_flag, location, navigate]);

  if (connect_flag[1]) return children;

  return null;
};

export const HaveAcc = ({ connect_flag, children }) => {
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (connect_flag[1]) {
      navigate("/Mint", { state: { from: location } });
    }
  }, [connect_flag, location, navigate]);

  if (!connect_flag[1]) return children;

  return null;
};